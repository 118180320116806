import {
  ActionTypeEnum,
  Agent,
  ApiResponseType,
  ApiSettings,
  Organization,
  PutApiSettingsUpdateResponseBody,
  ResourceTypeEnum,
  ScheduleState,
} from "@superblocksteam/shared";
import { noop } from "lodash";
import {
  SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS,
  SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
} from "env";
import { EventType } from "legacy/constants/ActionConstants";
import { SERVER_API_TIMEOUT_ERROR } from "legacy/constants/messages";
import { OrchestratorApiPaths } from "store/utils/orchestrator";
import { HttpError } from "store/utils/types";
import { sendErrorUINotification } from "utils/notification";
import { getUrlWithBranch } from "utils/urlWithBranch";
import { callServer, HttpMethod } from "../../utils/client";
import { callOrchestrator } from "../apisShared/call-orchestrator";
import * as BackendTypes from "./backend-types";
import { ExecutionResponse } from "./types";
import type { ApiDto } from "store/slices/apis/types";
import type { ApiDtoWithPb } from "store/slices/apisV2/slice";

export async function createV2Api(
  branch: string | undefined,
  api: BackendTypes.Api,
  pageId?: string,
  lastSuccessfulWrite?: Date,
) {
  let err: undefined | HttpError;

  const result = await callServer<ApiDto>(
    {
      method: HttpMethod.Post,
      url: getUrlWithBranch(`v3/apis`, branch),
      body: { api, pageId, lastSuccessfulWrite },
      query: { v2: "true" },
    },
    {
      notifyOnError: false,
      onError: (error: HttpError) => {
        err = error;
        // if error code is 409, modal will show, so no need to show notification
        if (error.code !== 409) {
          sendErrorUINotification({
            message: `Failed to save api: ${
              error.code === 20
                ? SERVER_API_TIMEOUT_ERROR
                : `${error.message} (${error.code})`
            }`,
          });
        }
      },
    },
  );

  if (err) {
    throw err;
  }

  return result;
}

/*
 * TODO, unused params that may be needed
 * Query params are also absent here despite being on V1 APIs
 * Most likely we need to add some special handling depending on the environment
 * We need to exclude block execution metadata from being included in the request when
 * not executing from the API builder.
 */
export async function executeV2Api(params: {
  body: BackendTypes.ApiV2ExecutionRequest;
  api: ApiDtoWithPb;
  environment: string;
  eventType: EventType;
  notifyOnSystemError: boolean;
  organization: Organization;
  responseType: ApiResponseType;
  onMessage?: (message: any) => void;
  processStreamEvents?: (event: BackendTypes.StreamEvent) => void;
  controlFlowOnlyFiles?: BackendTypes.FileRequestParam[];
  abortController?: AbortController;
  baseUrl: string;
  agents: Array<Agent>;
}): Promise<ExecutionResponse | undefined | void> {
  const {
    body,
    controlFlowOnlyFiles,
    api,
    notifyOnSystemError,
    eventType,
    onMessage,
    processStreamEvents,
    responseType,
    abortController,
    baseUrl,
    agents,
    organization,
  } = params;

  const init = {
    method: HttpMethod.Post,
    url: OrchestratorApiPaths.API_EXECUTE,
    body,
    controlFlowOnlyFiles,
    timeout: Number(SUPERBLOCKS_UI_EXECUTE_API_TIMEOUT_MS),
    baseUrl,
    responseType,
    query: {
      eventType,
    },
    agents,
    organization,
    abortController,
  } satisfies Parameters<typeof callOrchestrator>[0];

  try {
    const result = await callOrchestrator<BackendTypes.ApiV2ExecutionResponse>(
      init,
      {
        notifyOnError: notifyOnSystemError,
        shouldCrashApp: false,
      },
      onMessage,
      processStreamEvents,
    );

    return result;
  } catch (err) {
    const message = `Failed to execute ${
      api?.apiPb?.metadata?.name || "API"
    }. ${err}`;
    if (notifyOnSystemError) {
      sendErrorUINotification({
        message,
      });
    }
    return {
      systemError: message,
    };
  }
}

export async function persistV2Api({
  api,
  lastSuccessfulWrite,
  scheduleState,
  superblocksSupportUpdateEnabled = false,
  branch,
}: {
  api: BackendTypes.Api;
  lastSuccessfulWrite?: Date;
  scheduleState?: ScheduleState;
  superblocksSupportUpdateEnabled: boolean;
  branch: string | undefined;
}) {
  let err: undefined | HttpError;
  const query = { v2: "true" } as {
    v2: string;
    superblocksSupportUpdateEnabled?: string;
  };
  if (superblocksSupportUpdateEnabled) {
    query.superblocksSupportUpdateEnabled = "true";
  }
  const result = await callServer<ApiDto>(
    {
      method: HttpMethod.Put,
      // TODO always use branch based url
      url: getUrlWithBranch("v3/apis/:apiId", branch),
      params: { apiId: api.metadata.id },
      body: { api, lastSuccessfulWrite, scheduleState },
      timeout: SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
      query: query,
    },
    {
      notifyOnError: false,
      onError: (error: HttpError) => {
        err = error;
        // if error code is 409, modal will show, so no need to show notification
        if (error.code !== 409) {
          sendErrorUINotification({
            message: `Failed to save api: ${
              error.code === 20
                ? SERVER_API_TIMEOUT_ERROR
                : `${error.message} (${error.code})`
            }`,
          });
        }
      },
    },
  );

  if (err) {
    // update deleted integration will return 404, in this case we shouldn't crash app
    if (err?.code !== 404) throw err;
  }
  return result;
}

// delete does not require the v2 query param
export function deleteV2Api(apiId: string, branch: string | undefined) {
  return callServer<void>({
    method: HttpMethod.Delete,
    url: getUrlWithBranch("v3/apis/:apiId", branch),
    params: { apiId },
  });
}

export async function persistV2ApiSettings({
  id,
  settings,
  folderId,
  lastSuccessfulWrite,
  superblocksSupportUpdateEnabled,
  restricted,
}: {
  id: string;
  settings?: ApiSettings;
  folderId?: string | null;
  restricted?: boolean;
  lastSuccessfulWrite?: number;
  superblocksSupportUpdateEnabled: boolean;
}) {
  let err: undefined | HttpError;
  const result = await callServer<PutApiSettingsUpdateResponseBody>(
    {
      method: HttpMethod.Put,
      url: "v3/apis/:apiId/settings",
      params: { apiId: id },
      body: {
        ...(settings ? { settings } : {}),
        ...(folderId !== undefined ? { folderId } : {}),
        ...(restricted !== undefined ? { restricted } : {}),
        lastSuccessfulWrite,
      },
      timeout: SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
      query: superblocksSupportUpdateEnabled
        ? { superblocksSupportUpdateEnabled: "true" }
        : undefined,
    },
    {
      notifyOnError: false,
      onError: (error: HttpError) => {
        err = error;
        // if error code is 409, modal will show, so no need to show notification
        if (error.code !== 409) {
          sendErrorUINotification({
            message: `Failed to save api settings: ${
              error.code === 20
                ? SERVER_API_TIMEOUT_ERROR
                : `${error.message} (${error.code})`
            }`,
          });
        }
      },
    },
  );

  if (err) {
    throw err;
  }
  return result;
}

export async function updateScheduleState({
  scheduledJobId,
  scheduleState,
}: {
  scheduledJobId: string;
  scheduleState: ScheduleState;
}): Promise<void> {
  let err: undefined | HttpError;
  const result = await callServer<void>(
    {
      method: HttpMethod.Put,
      url: "v3/scheduled-jobs/:scheduledJobId/schedule-state",
      params: { scheduledJobId },
      body: {
        scheduleState,
      },
      timeout: SUPERBLOCKS_UI_SAVE_API_TIMEOUT_MS,
    },
    {
      notifyOnError: false,
      onError: (error: HttpError) => {
        err = error;
      },
    },
  );

  if (err) {
    throw err;
  }
  return result;
}

export const getApiPermissions = ({
  apiId,
  type,
}: {
  apiId: string;
  type: ResourceTypeEnum.WORKFLOWS | ResourceTypeEnum.SCHEDULED_JOBS;
}) => {
  return callServer<{
    permissions?: Array<ActionTypeEnum>;
  }>(
    {
      method: HttpMethod.Get,
      url: `v3/apis/${apiId}/permissions`,
      query: { type },
    },
    {
      notifyOnError: false,
      shouldCrashApp: false,
      onError: noop,
    },
  );
};
